// 12,15  (fr-CA, showSymbol = false)
// 12.15  (en-CA, showSymbol = false)
// 11,212.15  (en-CA, showSymbol = false)
// 12,15 $  (fr-CA, showSymbol = true)
// $12.15  (en-CA, showSymbol = true)

const CurrencyService = {
  toLocaleString(language, amount, currency, showSymbol) {
    if (amount === undefined) {
      return '';
    }
    
    const options = {
      style: showSymbol ? "currency" : "decimal",
      currency,
      currencyDisplay: showSymbol ? "symbol" : undefined,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
    
    console.log('Input values:', {
      language,
      amount,
      currency,
      showSymbol,
      options
    });

    const result = amount
      .toLocaleString(language, options)
      .replace(/[A-Z]{2,3}/, "")
      .trim()
    
    console.log('Formatted result:', result);
    return result;
  },
}

export default CurrencyService
